var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-card-title",
        [
          _vm._v(" Majority Vote Table"),
          _c(
            "v-btn",
            {
              attrs: {
                color: "indigo lighten-1",
                text: "",
                to: "response-table"
              }
            },
            [_vm._v("Responses")]
          ),
          _c(
            "v-btn",
            { attrs: { color: "indigo lighten-1", text: "", to: "func-view" } },
            [_vm._v("Func-View")]
          ),
          _c(
            "v-btn",
            {
              attrs: { icon: "", color: "green" },
              on: {
                click: function($event) {
                  return _vm.getMVData()
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-cached")])],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { icon: "", color: "black" },
              on: {
                click: function($event) {
                  return _vm.getExpData()
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-television")])],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { icon: "", color: "green" },
              on: {
                click: function($event) {
                  return _vm.changeExpData()
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-television")])],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { icon: "", color: "red" },
              on: {
                click: function($event) {
                  return _vm.completeExpData()
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-television")])],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { icon: "", color: "blue" },
              on: {
                click: function($event) {
                  return _vm.writeResultExpData()
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-television")])],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { icon: "", color: "yellow" },
              on: {
                click: function($event) {
                  return _vm.switchNum()
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-television")])],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { icon: "", color: "orange" },
              on: {
                click: function($event) {
                  return _vm.ApplyRequesterLabel()
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-television")])],
            1
          ),
          _c(
            "v-form",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            model: {
                              value: _vm.startI,
                              callback: function($$v) {
                                _vm.startI = $$v
                              },
                              expression: "startI"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            model: {
                              value: _vm.endI,
                              callback: function($$v) {
                                _vm.endI = $$v
                              },
                              expression: "endI"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-container",
            {
              staticClass: "mr-2",
              staticStyle: { width: "500px", fontSize: "15px" }
            },
            [
              _c(
                "v-stepper",
                { staticClass: "elevation-1", attrs: { "non-linear": "" } },
                [
                  _c(
                    "v-stepper-header",
                    [
                      _c(
                        "v-stepper-step",
                        {
                          attrs: { step: "1", editable: true },
                          on: {
                            click: function($event) {
                              _vm.isTest = false
                              _vm.isLaunch = false
                            }
                          }
                        },
                        [_vm._v("Prepare")]
                      ),
                      _c("v-divider"),
                      _c(
                        "v-stepper-step",
                        {
                          attrs: { step: "2", editable: true },
                          on: {
                            click: function($event) {
                              _vm.isTest = true
                              _vm.isLaunch = false
                            }
                          }
                        },
                        [_vm._v("Simulate")]
                      ),
                      _c("v-divider"),
                      _c(
                        "v-stepper-step",
                        {
                          attrs: { step: "3", editable: true },
                          on: {
                            click: function($event) {
                              _vm.isTest = false
                              _vm.isLaunch = true
                            }
                          }
                        },
                        [_vm._v("Launch")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-container",
            [
              _c(
                "v-card",
                { staticClass: "elevation-0" },
                [
                  _c("p", [_vm._v("Select Method")]),
                  _c("v-data-table", {
                    staticClass: "elevation-0",
                    attrs: {
                      "sort-by":
                        !_vm.isTest && !_vm.isLaunch
                          ? ["contCount"]
                          : ["workerNum", "match"],
                      "sort-desc":
                        !_vm.isTest && !_vm.isLaunch ? [true] : [false, true],
                      "footer-props": {
                        "items-per-page-options": [1, 3, 5, 10, -1]
                      },
                      "items-per-page": 1,
                      headers:
                        !_vm.isTest && !_vm.isLaunch
                          ? [
                              { text: "count", value: "contCount" },
                              { text: "continue", value: "continue" },
                              { text: "params(continue)", value: "pcont" },
                              { text: "terminate", value: "terminate" },
                              { text: "params(terminate)", value: "pterm" }
                            ]
                          : [
                              { text: "workerNum(total)", value: "workerNum" },
                              { text: "match(total)", value: "match" },
                              { text: "continue", value: "continue" },
                              { text: "params(continue)", value: "pcont" },
                              { text: "terminate", value: "terminate" },
                              { text: "params(terminate)", value: "pterm" }
                            ],
                      items: _vm.prepareTest,
                      "show-select": "",
                      "single-select": "",
                      "item-key": "pcont"
                    },
                    model: {
                      value: _vm.selectedMethod,
                      callback: function($$v) {
                        _vm.selectedMethod = $$v
                      },
                      expression: "selectedMethod"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            { staticClass: "text-end" },
            [
              _vm.selectedNanotasks.length > 0 && !_vm.isTest
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mx-3",
                                        attrs: {
                                          dark: "",
                                          color: "grey darken-2"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.$refs.additionalPost.shown = true
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-ticket-confirmation")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2679899004
                      )
                    },
                    [_c("span", [_vm._v("Assign new workers")])]
                  )
                : _vm._e(),
              _vm.selectedNanotasks.length > 0 && _vm.isTest
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mx-3",
                                        attrs: {
                                          dark: "",
                                          color: "grey darken-2"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.startTest()
                                          }
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v("mdi-ticket-confirmation")
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        578706688
                      )
                    },
                    [_c("span", [_vm._v("Start test")])]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-sheet",
        {
          staticClass: "d-flex justify-center",
          attrs: { tile: "", height: "81", flat: "" }
        },
        [
          _c(
            "v-btn",
            {
              staticClass: "ma-2",
              attrs: { icon: "", disabled: this.page == 1 },
              on: {
                click: function($event) {
                  return _vm.prev()
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-chevron-left")])],
            1
          ),
          _c(
            "v-form",
            [
              _c(
                "v-container",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: _vm.naturalNumRules,
                              label: "minimum",
                              required: "",
                              type: "number"
                            },
                            model: {
                              value: _vm.conditions.minimum,
                              callback: function($$v) {
                                _vm.$set(_vm.conditions, "minimum", _vm._n($$v))
                              },
                              expression: "conditions.minimum"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: _vm.ratioRules,
                              label: "continuation",
                              required: "",
                              type: "number"
                            },
                            model: {
                              value: _vm.conditions.continuation,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.conditions,
                                  "continuation",
                                  _vm._n($$v)
                                )
                              },
                              expression: "conditions.continuation"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: "12", md: "4" } },
                        [
                          _c("v-text-field", {
                            attrs: {
                              rules: _vm.naturalNumRules,
                              label: "termination",
                              required: "",
                              type: "number"
                            },
                            model: {
                              value: _vm.conditions.termination,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.conditions,
                                  "termination",
                                  _vm._n($$v)
                                )
                              },
                              expression: "conditions.termination"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-btn",
            {
              staticClass: "ma-2",
              attrs: { icon: "" },
              on: {
                click: function($event) {
                  return _vm.next()
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-chevron-right")])],
            1
          )
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          "multi-sort": "",
          "show-select": "",
          headers: _vm.table.headers,
          items: _vm.table.nanotasks,
          "footer-props": {
            "items-per-page-options": [5, 10, 15, 500, 1000, 1500, -1]
          },
          "item-key": "ID",
          "sort-by": _vm.isTest ? [""] : ["", ""],
          "sort-desc": _vm.isTest ? [true] : [true, false]
        },
        on: { "toggle-select-all": _vm.selectAllToggle },
        scopedSlots: _vm._u(
          [
            {
              key: "item.data-table-select",
              fn: function(ref) {
                var item = ref.item
                var isSelected = ref.isSelected
                var select = ref.select
                return [
                  _c("v-simple-checkbox", {
                    attrs: {
                      value: isSelected,
                      readonly: _vm.isTest
                        ? item.reference === null
                        : item.disabled,
                      disabled: _vm.isTest
                        ? item.reference === null
                        : item.disabled,
                      ripple: false
                    },
                    on: {
                      input: function($event) {
                        return select($event)
                      }
                    }
                  })
                ]
              }
            },
            _vm._l(
              Array.from({ length: _vm.maxWorkers }, function(v, k) {
                return k
              }),
              function(i) {
                return {
                  key: "item.worker" + i,
                  fn: function(ref) {
                    var item = ref.item
                    return [
                      _c("v-simple-table", {
                        key: i.id,
                        attrs: { dense: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function() {
                                return [
                                  _c(
                                    "tbody",
                                    _vm._l(item["worker" + i], function(
                                      value,
                                      key
                                    ) {
                                      return _c("tr", { key: key }, [
                                        _c(
                                          "td",
                                          { staticStyle: { width: "100px" } },
                                          [_c("b", [_vm._v(_vm._s(key))])]
                                        ),
                                        item["class" + i] === 0
                                          ? _c(
                                              "td",
                                              {
                                                staticClass: "underline-blue",
                                                staticStyle: {
                                                  "word-break": "break-all"
                                                }
                                              },
                                              [_vm._v(_vm._s(value))]
                                            )
                                          : _c(
                                              "td",
                                              {
                                                staticStyle: {
                                                  "word-break": "break-all"
                                                }
                                              },
                                              [_vm._v(_vm._s(value))]
                                            )
                                      ])
                                    }),
                                    0
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          true
                        )
                      })
                    ]
                  }
                }
              }
            ),
            {
              key: "item.result",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("v-simple-table", {
                    attrs: { dense: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function() {
                            return [
                              _c(
                                "tbody",
                                _vm._l(item.result, function(value, key) {
                                  return _c(
                                    "tr",
                                    { key: key },
                                    [
                                      _c(
                                        "td",
                                        { staticStyle: { width: "100px" } },
                                        [_c("b", [_vm._v(_vm._s(key))])]
                                      ),
                                      _c(
                                        "v-chip",
                                        {
                                          attrs: {
                                            color: _vm.getColor(item),
                                            dark: ""
                                          }
                                        },
                                        [
                                          _c(
                                            "td",
                                            {
                                              staticStyle: {
                                                "word-break": "break-all"
                                              }
                                            },
                                            [_vm._v(_vm._s(value))]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  })
                ]
              }
            },
            {
              key: "item.reference",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("v-simple-table", {
                    attrs: { dense: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function() {
                            return [
                              _c(
                                "tbody",
                                _vm._l(item.reference, function(value, key) {
                                  return _c("tr", { key: key }, [
                                    _c(
                                      "td",
                                      { staticStyle: { width: "100px" } },
                                      [_c("b", [_vm._v(_vm._s(key))])]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticStyle: {
                                          "word-break": "break-all"
                                        }
                                      },
                                      [_vm._v(_vm._s(value))]
                                    )
                                  ])
                                }),
                                0
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      true
                    )
                  })
                ]
              }
            }
          ],
          null,
          true
        ),
        model: {
          value: _vm.selectedNanotasks,
          callback: function($$v) {
            _vm.selectedNanotasks = $$v
          },
          expression: "selectedNanotasks"
        }
      }),
      _c("tutti-dialog", {
        ref: "additionalPost",
        attrs: {
          title: "Post selected Nanotasks",
          maxWidth: "350",
          actions: [
            {
              label: "Post",
              color: "error",
              test: true,
              onclick: _vm.updateNumAssignable
            },
            { label: "Cancel", color: "grey darken-1", text: true }
          ]
        },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function() {
              return [
                _vm._v(
                  " Are you sure you wish to delete the selected nanotasks? This operation cannot be undone. "
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }